/* DatePickerStyles.css */

/* Estilo para o calendário */
.custom-datepicker .MuiPickersCalendar-transitionContainer {
  background-color: #009c43; /* Defina a cor de fundo desejada */
}

.custom .css-bw88rr-MuiPickersMonth-monthButton.Mui-selected{color:#fff;background-color:#009c43;}

.custom .MuiPickersMonth-root .css-fldub7-MuiPickersMonth-root .css-bw88rr-MuiPickersMonth-monthButton.Mui-selected  .css-bw88rr-MuiPickersMonth-monthButton.Mui-selected:hover {
  color: #fff; /* Defina a cor do texto dos dias */
  background-color: #009c43; /* Defina a cor de fundo dos dias */
  
}

/* Estilo para os botões de navegação do calendário */
.custom-datepicker .MuiPickersArrowSwitcher-root button {
  color: #009c43; /* Defina a cor dos botões de navegação */
}

.css-bw88rr-MuiPickersMonth-monthButton.Mui-selected {
    color: #fff;
    background-color: #009c43;
}